@font-face {
  font-family: 'Baloo 2';
  src: url('./baloo2/Baloo2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Baloo 2';
  src: url('./baloo2/Baloo2-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Baloo 2';
  src: url('./baloo2/Baloo2-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Baloo 2';
  src: url('./baloo2/Baloo2-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}